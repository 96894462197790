import React, { useState, useEffect } from "react";
import { Box } from "@chakra-ui/react";

const HomeBanner = () => {
  const contentData = [
    {
      type: "video",
      videoUrl: "/HQ_Podcast.mp4",
      link: "https://www.youtube.com/watch?v=JBqra542B24",
    },
    {
      type: "video",
      videoUrl: "/HQ_New_Use_Case_CNG.mp4",
      link: "https://edexa.com/smart-city",
    },
    {
      type: "video",
      videoUrl: "/HQ_Substainability.mp4",
      link: "https://edexa.network/sustainability",
    },
    {
      type: "video",
      videoUrl: "/HQ_edeXa_Labs.mp4",
      link: "https://x.com/edeXaLabs",
    },
    {
      type: "image",
      imageUrl: "/Banner_EDX_Listed_CoinMarket_Cap.png",
      link: "https://coinmarketcap.com/currencies/edexa-service-token/",
    },
    {
      type: "video",
      videoUrl: "/HQ_New_Use_Case_bMessage.mp4",
      link: "https://edexa.com/be-message",
    },
    {
      type: "image",
      imageUrl: "/Banner_EDX_Listed_MEXC.png",
      link: "https://www.mexc.com/exchange/EDX_USDT",
    },
    {
      type: "video",
      videoUrl: "/HQ_New_Use_Case_bYou.mp4",
      link: "https://edexa.com/b-you",
    },
    {
      type: "image",
      imageUrl: "/Banner_EDX_Listed_CoinGecko.png",
      link: "https://www.coingecko.com/en/coins/edexa",
    },
  ];

  const [currentContentIndex, setCurrentContentIndex] = useState(0);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentContentIndex(
        (prevIndex) => (prevIndex + 1) % contentData.length
      );
    }, 8000);

    return () => clearInterval(intervalId);
  }, []);

  const currentContent = contentData[currentContentIndex];

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      height="125px"
      width="100%"
      bg="transparent"
    >
      <Box
        width="80%"
        maxWidth="800px"
        overflow="hidden"
        borderRadius="10px"
        boxShadow="none !important"
      >
        {currentContent.type === "video" ? (
          <video
            src={currentContent.videoUrl}
            autoPlay
            loop
            muted
            playsInline
            preload="auto"
            style={{
              objectFit: "cover",
              width: "100%",
              height: "auto",
              cursor: "pointer",
            }}
            onClick={() => window.open(currentContent.link, "_blank")}
          ></video>
        ) : (
          <img
            src={currentContent.imageUrl}
            alt="carousel content"
            style={{
              objectFit: "cover",
              width: "100%",
              height: "auto",
              cursor: "pointer",
            }}
            onClick={() => window.open(currentContent.link, "_blank")}
          />
        )}
      </Box>
    </Box>
  );
};

export default HomeBanner;
